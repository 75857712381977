<template>
 <div class="d-flex flex-column mx-auto" style="max-width: 600px;" v-if="recipes">
<!--   <div class="my-4">-->
<!--     <h3>Menus</h3>-->
<!--     <div v-for="item of menus" :key="`menu-${item.id}`">-->
<!--       {{item.title}}-->
<!--     </div>-->
<!--   </div>-->
   <div class="my-4">
     <h3 class="mb-2">Recipes</h3>
     <v-text-field
       v-model="search"
       prepend-icon="mdi-magnify"
       label="Search"
       filled
       rounded
       dense
       clearable
       single-line
       hide-details
       style="max-width: 100%;"
     />
     <v-card
       v-for="item of recipes"
       :key="`recipe-${item.recipe_id}`"
       class="px-8 pt-4 pb-4 my-4"
       outlined
     >
       <h3 class="mb-2">{{ item.recipe_title }}</h3>
       <div
         v-if="item.recipe_proportion_description"
         class="mb-2"
       >
         {{item.recipe_proportion_description}}
       </div>
       <div style="font-size: 10px">ID: {{item.recipe_id}}</div>
       <div
         v-if="item.recipe_proportion_id"
         style="font-size: 10px"
       >
         Proportion ID: {{item.recipe_proportion_id}}
       </div>
       <v-btn
         v-if="item.recipe_proportion_id"
         @click="openRecipe(item)"
         text
         class="mt-2"
       >Open</v-btn>
     </v-card>
   </div>
<!--   <div class="my-4">-->
<!--     <h3>Ingredients</h3>-->
<!--     <div v-for="item of ingredients" :key="`ingredient-${item.id}`">-->
<!--       {{item.title}}-->
<!--     </div>-->
<!--   </div>-->
 </div>
 <h3 v-else class="text-center">Loading...</h3>
</template>

<script>
export default {
  name: 'Recipes',
  data: () => ({
    search: '',
  }),
  async mounted() {
    await this.$api.main.getRecipesWithProportions();
  },
  computed: {
    recipes() {
      const data = this.$store.AppStore?.recipesData;
      if (!data) {
        return null;
      }
      if (!this.search) {
        return data;
      }
      return data.filter((el) => el.recipe_title.toLowerCase().includes(this.search.toLowerCase()));
    },
  },
  methods: {
    openRecipe(recipe) {
      window.open(`https://admin.online-kitchen.ru/recipes/${recipe.recipe_id}/proportions/${recipe.recipe_proportion_id}`, '_blank');
    },
  },
};
</script>

<style scoped>

</style>
